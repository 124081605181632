import React, { Suspense,useState, useEffect  } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { CCol, CRow, CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { Amplify } from 'aws-amplify'
import { Authenticator, useAuthenticator, SelectField, ThemeProvider, Theme, Button, Text, TextField, Heading, useTheme, View, Image, Radio, RadioGroupField } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate,useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom'

import CIcon from '@coreui/icons-react'
import { logoOriginal } from './assets/brand/logo-original'

//Amplify.configure(awsconfig);
// Amplify.configure({
//   aws_cognito_region: 'us-east-1', // (required) - Region where Amazon Cognito project was created
//   aws_user_pools_id: 'us-east-1_BurVfirnF', // (optional) -  Amazon Cognito User Pool ID
//   aws_user_pools_web_client_id: '37khhiv3vabsupm7kp14ju0lnd', // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
//   UsernameAttributes: 'email',
//   // aws_cognito_identity_pool_id:
//   //   'us-east-1:f602c14b-0fde-409c-9a7e-0baccbfd87d0', // (optional) - Amazon Cognito Identity Pool ID
//   aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
// })
Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  UsernameAttributes: process.env.REACT_APP_USERNAMEATTRIBUTES,
  // aws_cognito_identity_pool_id:
  //   'us-east-1:f602c14b-0fde-409c-9a7e-0baccbfd87d0', // (optional) - Amazon Cognito Identity Pool ID
  aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
})

function App() {
  const queryParams = new URLSearchParams(window.location.search)
  const urlSignup = queryParams.get('action') || ''
  const userId = queryParams.get('user_id') || ''
  let unitEmail = queryParams.get('email') ?? ''
  unitEmail = decodeURIComponent(unitEmail.replace(/ /g, '+'))

  const [initialState, setInitialState] = useState<string>("signIn");
  useEffect(() => {
    if(urlSignup === 'tenantSignup') {
      (userId && unitEmail) ? setInitialState("signUp")  : setInitialState("signIn")
    }
  }, []);

  const Uuid = userId? userId : uuidv4();
  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <CIcon customClassName="sidebar-brand-full" icon={logoOriginal} height={32} />
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy;2023 All Rights Reserved
          </Text>
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to continue
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
              className='primary'
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },
  
    SignUp: {
      FormFields() {
        return (
          <>
            <TextField
              name='username'
              placeholder=""
              value={Uuid}
              type='hidden'
              label=""
              errorMessage="There is an error"
            />
            <TextField
              type='email'
              placeholder="Enter your email"
              name="email"
              label="Email"
              readOnly = {(initialState ==="signUp") ? true : false }
              defaultValue={unitEmail}
              // type="hidden"
              errorMessage="Please provide a valid email"
            />
            <TextField
              type='password'
              placeholder="Enter your Password"
              name="password"
              label="Password"
            // type="hidden"
            />
            <TextField
              type='password'
              placeholder="Please confirm your Password"
              name="confirm_password"
              label="Confirm Password"
            // type="hidden"
            />
            <TextField
              placeholder=""
              name="custom:dome_role"
              label=""
              value="tenant"
              type="hidden"
            />
            {/* <RadioGroupField
              label="User role"
              name="custom:dome_role"
            >
              <Radio value="prop_owner">Owner</Radio>
              <Radio value="prop_manager">Manager</Radio>
              <Radio value="tenant">Tenant</Radio>
            </RadioGroupField> */}
          </>
        );
      },
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <>
            <View textAlign="center">
              <Button
                fontWeight="normal"
                onClick={toSignIn}
                size="small"
                variation="link"
              >
                Back to Sign In
              </Button>
            </View>
          </>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>DomeSeer</Text>;
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };
  
  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      email: {
        label: 'Email',
        placeholder: 'Enter your email',
        isRequired: true,
        order: 1,
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your Password',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Password',
        order: 3,
      },
    },
  }
  // Containers
  const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

  return (
    <>   
        <Authenticator 
        loginMechanisms={['username']} 
        initialState= {(initialState === "signIn") ? "signIn" : "signUp" }
        formFields={formFields} 
        components={components}>
          <HashRouter>
            <Suspense fallback={<CSpinner color="primary" />}>
              <Routes>
                <Route path="*" element={<DefaultLayout />} />
              </Routes>
            </Suspense>
          </HashRouter>
        </Authenticator>

    </>
  )
}

export default App
